<template>
  <v-container>
    <div id="app">
      <section style="margin-top: 40vh">
        <div class="item">
          <img alt="NovaXS Logo" src="../assets/logo.png">
        </div>
        <div style="margin-top: 20px; margin-bottom: 20px;">
          <a style="color: #878787" href="/update/setup-pdvxs.exe">Download Windows</a>
          -
          <a style="color: #878787" href="/update/setup-pdvxs.deb">Download Linux</a>
        </div>
        <div style="margin-top: 20px; margin-bottom: 20px;">
          <a style="color: #878787" href="/login">Painel Administrativo</a>
        </div>
        <div  style="color: #878787">
          NovaXS - Todos os Direitos Reservados
        </div>
      </section>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Index',

    data: () => ({

    }),
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #e3e3e3;
  width: 100%  ;
}
</style>
